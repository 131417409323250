import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Animal from "../components/animal-list-item"
import SEO from "../components/seo"

import animalStyles from "../styles/animal-list.module.scss"

export const query = graphql`
  query AnimalQuery {
    animals: allSanityAnimal {
      edges {
        node {
          id
          name
          slug {
            current
          }
          image {
            asset {
              fluid(maxWidth: 300, maxHeight: 300) {
                ...GatsbySanityImageFluid
              }
            }
          }
          alt
          _rawExcerpt
          type
        }
      }
    }
  }
`

const AnimalGrid = props => {
  return (
    <section className={animalStyles.animalsContainer}>
      <SEO
        title="Meet the Critters"
        description="Find out about all the different critters we have at Crazy Critters Experience"
      />
      <h1>Meet The Critters</h1>
      {props.nodes &&
        props.nodes.map(node => (
          <Animal
            container={animalStyles.animalContainer}
            imageWrapper={animalStyles.imageWrapper}
            content={animalStyles.content}
            text={animalStyles.text}
            {...node}
            key={node.id}
          />
        ))}
    </section>
  )
}

const Animals = ({ data }) => {
  const animalNodes = data && data.animals.edges.map(edge => edge.node)
  return <Layout>{animalNodes && <AnimalGrid nodes={animalNodes} />}</Layout>
}

export default Animals
