import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import BlockText from "./block-text"
// import { buildImageObj } from "../utils/helpers"
// import { imageUrlFor } from "../utils/image-url"

const animal = props => {
  return (
    <article className={props.container} style={props.style}>
      <div className={props.content}>
        <Img fluid={props.image.asset.fluid} className={props.imageWrapper} />
        {/* {props.image && props.image.asset && (
          <img
            src={imageUrlFor(buildImageObj(props.image))
              .width(600)
              .height(600)
              .fit("crop")
              .crop("focalpoint")
              .url()}
            alt={props.alt}
          />
        )} */}
        <div className={props.text}>
          <h2>
            Meet {props.name}, the {props.type}
          </h2>
          {props._rawExcerpt && <BlockText blocks={props._rawExcerpt} />}
          <Link to={`/animals/${props.slug.current}`}>
            Read more about {props.name}....
          </Link>
        </div>
      </div>
    </article>
  )
}

export default animal
